import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import { breakpoints, Container } from '../../styles/layout';
import {
  typeN16bu,
  typeN16r,
  typeN16ru,
  typeN21bu,
  typeN21r,
  typeN21ru,
  typeT38sb,
  typeT84sb,
} from '../../styles/typography/presets';
import { colors } from '../../styles/colors';
import FluidImg from '../shared/FluidImg';
import { fonts } from '../../styles/typography';

const Title = styled.h1`
  ${typeN16bu};
  color: ${colors.white};
`;

const Message = styled.h2`
  ${typeT38sb};
  color: ${colors.white};
`;

const Brief = styled.div`
  ${typeN16r};
  color: ${colors.white};
  
  p {
    margin-bottom: 1.5em;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Image = styled.div`

`;

const GradientBackground = styled.div`
  background: linear-gradient(to top right, ${colors.pink} 25%, ${colors.purple} 75%);
`;

const SectionLink = styled.a`
  ${typeN16ru};
  display: block;
  color: ${colors.darkBlue};
  text-decoration: none;
  &:before {
    content: '→ ';
    font-family: ${fonts.national.family};
  }
`;

const SectionLinks = styled.div`

`;

const CustomContainer = styled(Container)`
  grid-template-rows: repeat(6, auto);
  ${Image} {
    grid-column: 1 / span 10;
    grid-row: 1 / span 3;
    z-index: -1;
  }
  
  ${Title} {
    grid-column: 2 / span 4;
    grid-row: 2;
    margin-top: 0;
    margin-bottom: 12px;
  }
  
  ${Message} {
    grid-column: 2 / span 8;
    grid-row: 3 / span 2;
    margin-top: 0;
    margin-bottom: 40px;
  }
  
  ${GradientBackground} {
    grid-column: 1 / span 10;
    grid-row: 4 / span 2;
    z-index: -1;
  }
  
  ${Brief} {
    grid-column: 3 / span 7;
    padding-left: 15px;
    grid-row: 5;
    margin-bottom: 70px;
  }
  
  ${SectionLinks} {
    grid-column: 2 / span 8;
    padding: 30px 0;
  }

  @media(min-width: ${breakpoints.lg}) {
    grid-template-rows: 90px auto auto auto 60px;
    ${Title} {
      ${typeN21bu};
      grid-column: 2 / span 3;
      grid-row: 2 / span 1;
      align-self: flex-end;
      margin-bottom: 90px;
      position: relative;
      display: flex;
      align-items: center;
      &:after {
        content: '';
        margin-left: 30px;
        display: inline-block;
        background: ${colors.white};
        height: 2px;
        flex-grow: 1;
      }
    }
    ${Message} {
      ${typeT84sb};
      grid-column: 5 / span 4;
      grid-row: 2 / span 1;
      align-self: flex-end;
      margin-bottom: 60px;
      margin-top: 110px;
    }
    ${Brief} {
      ${typeN21r};
      grid-column: 2 / span 4;
      grid-row: 3 / span 2;
      padding-left: 0;
    }
    ${Image} {
      z-index: -2;
      grid-column: 5 / span 6;
      grid-row: 2 / span 2;
    }
    ${SectionLinks} {
      grid-row: 4 / span 2;
      grid-column: 7 / span 3;
      margin-top: 60px;
      margin-left: 25px;
      
      ${SectionLink} {
        ${typeN21ru};
      }
    }
    ${GradientBackground} {
      z-index: -1;
      grid-column: 1 / span 6;
      grid-row: 1 / span 4;
      border-bottom-right-radius: 6px;
    }
  }
`;

const AboutHero = (props) => {
  const pageData = props.pageData;

  return (
    <CustomContainer>
      <Title>Who We Are</Title>
      <Message>{pageData.hero_message}</Message>
      <Brief dangerouslySetInnerHTML={{__html: pageData.hero_brief.html}}/>
      <Image>
        <FluidImg
          image={pageData.hero_image}
        />
      </Image>
      <GradientBackground/>
      {/*<SectionLinks>*/}
        {/*<SectionLink href={'#philosophy'}>Our Philosophy</SectionLink>*/}
        {/*<SectionLink href={'#beliefs'}>Statement of Belief</SectionLink>*/}
        {/*<SectionLink href={'#purpose'}>Purpose, Vision & Mission</SectionLink>*/}
        {/*<SectionLink href={'#values'}>Our Values</SectionLink>*/}
        {/*/!*<SectionLink>People</SectionLink>*!/*/}
        {/*/!*<SectionLink>Accreditations</SectionLink>*!/*/}
      {/*</SectionLinks>*/}
    </CustomContainer>
  )
};


export default AboutHero;