import React from 'react';
import styled from 'styled-components';
import { breakpoints, Container } from '../../styles/layout';
import { rtypeN21bu, rtypeN38r, typeT38sb, typeT84sb } from '../../styles/typography/presets';
import { colors } from '../../styles/colors';
import { buttonBase } from '../buttons';

const Title = styled.div`
  visibility: ${props => props.show ? 'visible': 'hidden'};
  opacity: ${props => props.show ? 1 : 0};
  ${typeT38sb};
  color: ${colors.white};
  padding-bottom: 15px;
  border-bottom: 6px solid ${colors.white};
  cursor: pointer;
  transition: all 500ms ease-out;
  
  &:hover {
    border-color: ${colors.orange};
  }
`;

const Message = styled.div`
  ${rtypeN21bu};
  color: ${colors.white};
`;

const Brief = styled.div`
  ${rtypeN38r};
  color: ${colors.white};
  max-width: 900px;
`;

const CloseButton = styled.button`
  ${buttonBase};
  cursor: pointer;
  &:before, &:after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(45deg);
    width: 100%;
    height: 2px;
    transition: background 500ms ease-out;
    background: ${colors.white};
  }
  &:after {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  &:hover {
    &:before, &:after {
      background: ${colors.orange};
    }
  }
`;

const DetailsContainer = styled.div`
  visibility: ${props => props.show ? 'visible' : 'hidden'};
  opacity: ${props => props.show ? 1 : 0};
  transition: all 500ms ease-out;
  text-align: center;
  padding: 30px 15px;
  
  position: relative;
  ${Message} {
    margin-bottom: 45px;
  }
  ${CloseButton} {
    position: absolute;
    top: 30px;
    right: 0;
    width: 30px;
    height: 30px;
  }
  
  @media(min-width: ${breakpoints.lg}) {
    padding: 120px 60px;
    ${CloseButton} {
      position: absolute;
      top: 120px;
      right: 0;
      width: 60px;
      height: 60px;
    }
  
  }
`;

const Details = (props) => {
  return (
    <DetailsContainer show={props.show}>
      <Message>{props.message}</Message>
      <Brief dangerouslySetInnerHTML={{ __html: props.content }}/>
      <CloseButton onClick={props.close}/>
    </DetailsContainer>
  );
};

const BackgroundImage = styled.div`
  visibility: ${props => props.show ? 'visible': 'hidden'};
  opacity: ${props => props.show ? 0.3 : 0};
  transition: all 500ms ease-out;
  background-image: url("${props => props.image}");
  background-position: center;
  background-size: cover;
  border-radius: 6px;
  overflow: hidden;
`;

const Content = styled.div`

`;

const CustomContainer = styled(Container)`
  background: linear-gradient(to top right, ${colors.pink}, ${colors.purple});
  
  grid-template-rows: 80px auto 80px;
  
  ${Content} {
    z-index: 1;
    grid-column: 2 / span 8;
    grid-row: 2 / span 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    min-height: 480px;
    
    position: relative;
    ${DetailsContainer} {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      height: 100%;
      max-width: 900px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }
  
  ${BackgroundImage} {
    z-index: 0;
    grid-column: 2 / span 8;
    grid-row: 2 / span 1;
  }
  
  @media(min-width: ${breakpoints.lg}) {
    grid-template-rows: auto;
    padding: 60px 0;
    align-items: stretch;
    
    ${Content} {
      z-index: 1;
      grid-row: 1;
      grid-column: 2 / span 8;
      min-height: 600px;
      
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      position: relative;
      ${Title} {
        ${typeT84sb};
      }
      ${DetailsContainer} {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 100%;
        height: 100%;
        max-width: 900px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
    }
    ${BackgroundImage} {
      z-index: 0;
      grid-row: 1;
      grid-column: 3 / span 6;
    }
  }
`;

class PurposeVisionMissionSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      briefOpen: null,
    };
  }

  openBrief = (briefName) => {
    this.setState({
      briefOpen: briefName,
    });
  };

  closeBrief = () => {
    this.setState({ briefOpen: null });
  };

  render() {
    const { pageData } = this.props;

    return (
      <CustomContainer id={'purpose'}>
        <Content>
          <Title show={!this.state.briefOpen} onClick={() => this.openBrief('Purpose')}>Purpose</Title>
          <Details
            show={this.state.briefOpen === 'Purpose'}
            close={this.closeBrief}
            message={'Our Purpose'}
            content={pageData.purpose_brief.html}
          />
          <Title show={!this.state.briefOpen} onClick={() => this.openBrief('Vision')}>Vision</Title>
          <Details
            show={this.state.briefOpen === 'Vision'}
            close={this.closeBrief}
            message={'Our Vision'}
            content={pageData.vision_brief.html}
          />
          <Title show={!this.state.briefOpen} onClick={() => this.openBrief('Mission')}>Mission</Title>
          <Details
            show={this.state.briefOpen === 'Mission'}
            close={this.closeBrief}
            message={'Our Mission'}
            content={pageData.mission_brief.html}
          />
        </Content>
        <BackgroundImage show={!this.state.briefOpen} image={pageData.vision_image.url}/>
      </CustomContainer>
    );
  }
}

export default PurposeVisionMissionSection;