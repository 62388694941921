import React from 'react';
import { Helmet } from 'react-helmet';
import { descriptionTags, imageTags, titleTags } from '../utilities/head-tags';
import AboutHero from '../components/about/AboutHero';
import PhilosophySection from '../components/about/PhilosophySection';
import StatementOfBeliefSection from '../components/about/StatementOfBeliefSection';
import PurposeVisionMissionSection from '../components/about/PurposeVisionMissionSection';
import ValuesSection from '../components/about/ValuesSection';
import Layout from '../components/layouts';
import { graphql } from 'gatsby';
import GradientBlocks from '../components/shared/GradientBlocks';
import Hero from '../components/shared/Hero';
import { colors } from '../styles/colors';


class AboutPage extends React.Component {
  componentDidMount() {
    window.analytics && window.analytics.page('Who We Are', {
      title: `Who We Are | ${this.props.data.prismicSite.data.name}`,
      url: window.location.href,
      path: window.location.pathname,
    });
  }

  render() {
    const props = this.props;
    const pageData = props.data.prismicAboutPage.data;

    return (
      <Layout reverseLogo={true}>
        <Helmet>
          {titleTags(`Who We Are | ${props.data.prismicSite.data.name}`)}
          {descriptionTags(pageData.meta_description)}
          {imageTags(pageData.hero_image.url)}
        </Helmet>
        <Hero
          brief={pageData.hero_brief.html}
          message={pageData.hero_message}
          image={pageData.hero_image}
          title={'Who we are'}
        />
        <GradientBlocks
          blocks={[{
            title: pageData.journey_title,
            message: pageData.journey_message,
            brief: pageData.journey_brief.html,
            image: pageData.journey_image,
          }]}
          id="about-journey"
        />
        <PhilosophySection pageData={pageData}/>
        <StatementOfBeliefSection pageData={pageData}/>
        <PurposeVisionMissionSection pageData={pageData}/>
        <ValuesSection pageData={pageData}/>
        <GradientBlocks
          blocks={[{
            title: pageData.children_title,
            message: pageData.children_message,
            secondary_message: pageData.children_secondary_message,
            brief: pageData.children_brief.html,
            secondary_brief: pageData.children_secondary_brief.html,
            image: pageData.children_image,
          }]}
          id="about-children"
        />
      </Layout>
    );
  }
}

export default AboutPage;


export const query = graphql`
  query AboutQuery {
    prismicSite {
      data {
        name
      }
    }
    prismicAboutPage {
      data {
        meta_description
        hero_message
        hero_brief {
          html
        }
        hero_image {
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }

            }
          }
        }
        journey_title
        journey_message
        journey_brief {
          html
          text
        }
        journey_image {
          alt
          copyright
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, maxHeight: 1920, quality: 90, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        philosophy_message
        philosophy_brief {
          html
        }
        philosophy_image {
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }

            }
          }
        }
        affirmation_title
        affirmation_items {
          title
          brief {
            html
          }
        }
        identity_title
        identity_brief {
          html
        }
        purpose_brief {
          html
        }
        mission_brief {
          html
        }
        vision_brief {
          html
        }
        vision_image {
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, quality: 90) {
                ...GatsbyImageSharpFluid_withWebp
              }

            }
          }
        }
        values_items {
          title
          brief {
            html
          }
        }
        children_title
        children_message
        children_brief {
          html
          text
        }
        children_secondary_message
        children_secondary_brief {
          html
          text
        }
        children_image {
          alt
          copyright
          url
          localFile {
            childImageSharp {
              fluid(maxWidth: 1920, maxHeight: 1920, quality: 90, cropFocus: CENTER) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;
