import React from 'react';
import styled from 'styled-components';
import { breakpoints, Container } from '../../styles/layout';
import {
  typeN14r,
  typeN16bu,
  typeN16r,
  typeN21bu,
  typeT16sb,
  typeT21sb,
  typeT38sb,
} from '../../styles/typography/presets';
import { colors } from '../../styles/colors';


const Title = styled.h2`
  ${typeT21sb};
`;

const AffirmsTitle = styled.h3`
  ${typeN16bu};
  padding-bottom: 30px;
  border-bottom: 1px solid ${colors.orange};
  margin-bottom: 30px;
`;

const AffirmationItemNumber = styled.span`
  ${typeN14r};
  color: ${colors.orange};
`;

const AffirmationItemTitle = styled.span`
  ${typeT16sb};
  color: ${colors.darkBlue};
`;

const AffirmationItemBrief = styled.span`
  ${typeN14r};
  color: ${colors.darkBlue};
`;

const AffirmationItem = styled.li`
  color: ${colors.orange};
`;

const AffirmationItems = styled.ol`
  list-style-type: none;
  padding: 0;
  margin: 0;
`;

const AffirmationSection = styled.div`

`;

const IdentityTitle = styled.h3`
  ${typeN21bu};
  padding-bottom: 30px;
  border-bottom: 1px solid ${colors.orange};
  margin-bottom: 30px;
`;

const IdentityBrief = styled.div`
  ${typeN16r};
  
  ul {
    padding-left: 1em;
    list-style-type: none;
    li {
      position: relative;
      &:before {
        content: '•';
        position: absolute;
        left: -1em;
      }
    }
  }
`;

const IdentitySection = styled.div`

`;

const Background = styled.div`
  background: ${colors.orangishGrey};
`;

const CustomContainer = styled(Container)`
  grid-template-rows: 60px auto auto auto;
  ${Title} {
    grid-column: 2 / span 8;
    grid-row: 2;
    margin-bottom: 42px;
  }
  
  ${AffirmationSection} {
    grid-column: 2 / span 8;
    grid-row: 3;
    padding-bottom: 90px;
    
    ${AffirmationItems} {
      ${AffirmationItem} {
        display: flex;
        align-items: flex-start;
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0;
        }
        
        ${AffirmationItemNumber} {
          min-width: 45px;
        }
        
        ${AffirmationItemTitle} {
          display: inline-block;
          min-width: 80px;
          margin-right: 15px;
        }
      }
    }
  }

  ${IdentitySection} {
    grid-column: 2 / span 8;
    grid-row: 4;
    z-index: 1;
    padding: 85px 0;
  }
  
  ${Background} {
    grid-row: 4;
    grid-column: 1 / span 10;
    z-index: 0;
  }
  
  @media(min-width: ${breakpoints.lg}) {
    grid-template-rows: 120px auto auto 110px;
    ${Title} {
      ${typeT38sb};
      grid-row: 2 / span 1;
      grid-column: 2 / span 4;
      margin-bottom: 54px;
    }
    ${AffirmationSection} {
      grid-row: 3 / span 1;
      grid-column: 2 / span 4;
      
      ${AffirmsTitle} {
        ${typeN21bu};
      }
      ${AffirmationItems} {
        ${AffirmationItem} {
          display: flex;
          align-items: flex-start;
          margin-bottom: 30px;
          &:last-child {
            margin-bottom: 0;
          }
          
          ${AffirmationItemNumber} {
            ${typeN16r};
            min-width: 45px;
          }
          
          ${AffirmationItemTitle} {
            ${typeT21sb};
            display: inline-block;
            min-width: 95px;
            margin-right: 30px;
          }
          ${AffirmationItemBrief} {
            ${typeN16r};
          }
        }
      }
      
    }
    ${IdentitySection} {
      grid-row: 3 / span 1;
      grid-column: 7 / span 3;
      padding: 0;
    }
    ${Background} {
      z-index: -1;
      grid-row: 1 / span 4;
      grid-column: 6 / span 5;
      margin-left: 100px;
    }
  }
`;

const StatementOfBeliefSection = ({ pageData }) => {
  return (
    <CustomContainer id={'beliefs'}>
      <Title>Our Statement of Belief</Title>
      <AffirmationSection>
        <AffirmsTitle>{pageData.affirmation_title}</AffirmsTitle>
        <AffirmationItems>
          {pageData.affirmation_items.map((affItem, index) => (
            <AffirmationItem key={index}>
              <AffirmationItemNumber>{(index + 1).toString().padStart(2, '0')}</AffirmationItemNumber>
              <AffirmationItemTitle>{affItem.title}</AffirmationItemTitle>
              <AffirmationItemBrief dangerouslySetInnerHTML={{__html: affItem.brief.html}}/>
            </AffirmationItem>
          ))}
        </AffirmationItems>
      </AffirmationSection>
      <IdentitySection>
        <IdentityTitle>{pageData.identity_title}</IdentityTitle>
        <IdentityBrief dangerouslySetInnerHTML={{__html: pageData.identity_brief.html}}/>
      </IdentitySection>
      <Background/>
    </CustomContainer>
  );
};

export default StatementOfBeliefSection;
