import React from 'react';
import styled from 'styled-components';
import { breakpoints, Container } from '../../styles/layout';
import Img from 'gatsby-image';
import { colors } from '../../styles/colors';
import { typeN16bu, typeN16r, typeN21bu, typeN21r, typeT38sb, typeT84sb } from '../../styles/typography/presets';
import FluidImg from '../shared/FluidImg';


const Title = styled.h2`
  ${typeN16bu};
  color: ${colors.white};
`;

const Message = styled.div`
  ${typeT38sb};
  color: ${colors.white};
`;

const Brief = styled.div`
  ${typeN16r};
  color: ${colors.white};
  
  p {
    margin-bottom: 1.5em;
    &:last-child {
      margin-bottom: 0;
    }
  }
`;

const Image = styled.div`
  border-radius: 6px;
  overflow: hidden;
`;


const CustomContainer = styled(Container)`
  background: linear-gradient(to top right, ${colors.orange}, ${colors.yellow});
  grid-template-rows: 40px auto auto 22px auto auto 60px;
  
  ${Title} {
    grid-row: 3;
    grid-column: 4 / span 4;
    z-index: 1;
    text-align: center;
  }
  
  ${Message} {
    grid-row: 4 / span 2;
    grid-column: 3 / span 6;
    z-index: 1;
    text-align: center;
    margin-bottom: 30px;
  }
  
  ${Image} {
    grid-row: 2 / span 3;
    grid-column: 2 / span 8;
    z-index: 0;
  }
  
  ${Brief} {
    grid-row: 6 / span 1;
    grid-column: 2 / span 8;
  }
  
  @media(min-width: ${breakpoints.lg}) {
    grid-template-rows: 120px auto auto 45px auto auto 150px;
    
    ${Image} {
      z-index: 0;
      grid-row: 2 / span 3;
      grid-column: 2 / span 8;
      margin: 0 20px;
    }
    
    ${Title} {
      ${typeN21bu};
      z-index: 1;
      grid-row: 3 / span 1;
      grid-column: 5 / span 2;
      text-align: center;
      margin-bottom: 30px;
    }
    
    ${Message} {
      ${typeT84sb};
      z-index: 1;
      grid-row: 4 / span 2;
      grid-column: 4 / span 4;
      text-align: center;
      margin-bottom: 60px;
    }
    
    ${Brief} {
      ${typeN21r};
      grid-row: 6 / span 1;
      grid-column: 3 / span 6;
      column-count: 2;
      column-gap: 30px;
    }
  }
`;

const PhilosophySection = ({ pageData, ...props }) => {
  return (
    <CustomContainer id={'philosophy'}>
      <Title>Our Philosophy</Title>
      <Message>{pageData.philosophy_message}</Message>
      <Brief dangerouslySetInnerHTML={{ __html: pageData.philosophy_brief.html }}/>
      <Image>
        <FluidImg
          image={pageData.philosophy_image}
        />
      </Image>
    </CustomContainer>
  );
};


export default PhilosophySection;